<template>
  <vx-card :title="title">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="my-2" type="border" color="danger" v-on:click="handleClose">
          Back
        </vs-button>
      </div>
    </div>
    <!-- Ngetes tabs -->
    <div class="vx-row mt-3">
      <vs-tabs v-if="rendered" ref="tabs">
        <vs-tab v-for="(dt, i) in dataCustomerOrder" :key="i" :label="''+(dt.code)" @click="redirectTab(i)">
          <!-- Start -->
          <!-- Pembagian dan pengelompokan dropdown -->
          <!-- 1st row : Customer Data & Segmentation Data -->
          <div class="vx-row mt-3">
            <!-- Customer Data -->
            <div class="vx-col sm:w-1/2 w-full mb-base">
              <span class="py-2 h5 font-semibold">Customer Data</span><hr>
              <!-- One time cust -->
              <div class="vx-row my-6">
                <div class="vx-col sm:w-1/3">
                  One Time
                </div>
                <div class="vx-col sm:w-2/6">
                  <vs-switch
                    color="dark"
                    icon-pack="feather"
                    vs-icon-on="icon-check-circle"
                    vs-icon-off="icon-slash"
                    v-model="dt.is_onetime"
                    :disabled="true"
                  >
                    <span slot="on">YES</span>
                    <span slot="off">NO</span>
                  </vs-switch>
                </div>
              </div>
              <!-- Ship To -->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Shipp To</span>
                </div>
                <div v-if="!dt.is_onetime" class="vx-col sm:w-2/3 w-full">
                  <vs-input
                    class="w-full"
                    type="text"
                    readonly
                    :value="dt.ship_to_code + ' - ' + dt.ship_to_name"
                  />
                </div>
                <!-- Address Ship To -->
                <div v-if="!dt.is_onetime" class="vx-col sm:w-1/3 w-full">
                  <span></span>
                </div>
                <div v-if="!dt.is_onetime" class="vx-col sm:w-2/3 w-full">
                  <vs-input
                    class="w-full"
                    type="text"
                    readonly
                    :value="dt.ship_to_address"
                  />
                </div>
                <div v-else class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.onetime_cust_data.contact_person + ' - ' + dt.onetime_cust_data.shipto_address" disabled/>
                </div>
              </div>

              <!-- Bill To -->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Bill to</span>
                </div>
                <div v-if="!dt.is_onetime" class="vx-col sm:w-2/3 w-full">
                  <vs-input
                    class="w-full"
                    type="text"
                    readonly
                    :value="dt.bill_to_code + ' - ' + dt.bill_to_name"
                  />
                </div>
                <div v-else class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.onetime_cust_data.billto_address" disabled/>
                </div>
              </div>

              <!-- Customer -->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Customer</span>
                </div>
                <div v-if="!dt.is_onetime" class="vx-col sm:w-2/3 w-full">
                  <vs-input
                    class="w-full"
                    type="text"
                    readonly
                    :value="dt.customer_code + ' - ' + dt.customer_name"
                  />
                </div>
                <div v-else class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="'['+dt.customer_code + ' - ' + dt.customer_name+'] ' + dt.onetime_cust_data.name" readonly/>
                </div>
              </div>

              <!-- Customer Name-->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Customer Name</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.customer_name" readonly/>
                </div>
              </div>

              <!-- Create By-->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Create By</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.created_by_name" readonly/>
                </div>
              </div>

              <!-- Creation Date-->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Creation Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="formatDate(dt.created_at)" readonly/>
                </div>
              </div>

              <!-- Customer Deposits -->
              <div v-if="dt.category == 'ZDCBD'" class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Customer Deposits</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedCustDeposit"
                    :options="optionCustDeposit"
                    :multiple="true"
                    :allow-empty="false"
                    track-by="ID"
                    :max-height="200"
                    placeholder="Type to search"
                    :custom-label="customLableCustDeposit"
                    :disabled="true"
                  />
                </div>
              </div>

              <!-- Form onetime cust -->
              <div v-if="dt.is_onetime" class="w-full mt-6">
                  <span class="py-2 h5 font-semibold">One Time Customer Data</span><hr>

                  <!-- Customer Name-->
                  <div class="vx-row mb-6 mt-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Customer Name</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.name : ''" readonly/>
                    </div>
                  </div>

                  <!-- Country-->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Country</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.country : ''" readonly/>
                    </div>
                  </div>

                  <!-- Province-->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Province</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.province : ''" readonly/>
                    </div>
                  </div>

                  <!-- City-->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>City</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.city : ''" readonly/>
                    </div>
                  </div>

                  <!-- District-->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>District</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.district : ''" readonly/>
                    </div>
                  </div>

                  <!-- Subdistrict -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Subdistrict</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.sub_district : ''" readonly/>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Address</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.billto_address : ''" readonly/>
                    </div>
                  </div>

                  <!-- Postal Code -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Postal Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.postal_code : ''" readonly/>
                    </div>
                  </div>

                  <!-- Ship / Bill Address -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Ship / Bill Address</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.billto_address : ''" readonly/>
                    </div>
                  </div>

                  <!-- Contact Person -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Contact Person</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.contact_person : ''" readonly/>
                    </div>
                  </div>

                  <!-- NIK -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>NIK</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.nik : ''" readonly/>
                    </div>
                  </div>

                  <!-- Phone Number -->
                  <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                      <span>Phone Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                      <vs-input class="w-full" :value="dt.is_onetime ? dt.onetime_cust_data.phone : ''" readonly/>
                    </div>
                  </div>
              </div>
              <!-- End form otcust -->
            </div>
            <!-- </div> -->
            <!-- Segmentation Data -->
            <div class="vx-col sm:w-1/2 w-full mb-base">
              <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>

              <!-- Territory -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.territory_code + ' - ' + dt.territory_name" readonly/>
                </div>
              </div>

              <!-- Zone -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Zone</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.zone_code + ' - ' + dt.zone_name" readonly/>
                </div>
              </div>

              <!-- Sales Channel -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Sales Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.sales_channel_name" readonly/>
                </div>
              </div>

              <!-- Distribution Channel -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Distribution Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.distribution_channel" readonly/>
                </div>
              </div>

              <!-- Sales ID -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Sales ID</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.personal_code_external + ' - ' + dt.personal_name" readonly/>
                </div>
              </div>

              <!-- Pricing Group -->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Pricing Group</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.pricing_group_name + ' (' +dt.pricing_group_code+ ')'" readonly/>
                </div>
              </div>
            </div>
          </div>
          <!-- 2nd row : SO Data & Delivery Data -->
          <div class="vx-row">
            <!-- SO Data -->
            <div class="vx-col sm:w-1/2 w-full mb-base">
              <span class="pt-2 h5 font-semibold">SO Data</span><hr>

              <!-- SO Code-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>SO Code</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.code" readonly/>
                </div>
              </div>

              <!-- Ref. PO Code-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Ref. PO Code</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.ex_code" readonly/>
                </div>
              </div>

              <!-- SO Type-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>SO Type</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.coType.Code + ' - ' + dt.coType.Description" readonly/>
                </div>
              </div>

              <!-- Date-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="formatDate(dt.date)" readonly/>
                </div>
              </div>

              <!-- PO Date-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>PO Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="formatDate(dt.ex_date)" readonly/>
                </div>
              </div>

              <!-- Payment Term-->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Payment Term</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.top_name" readonly/>
                </div>
              </div>
            </div>

            <!-- Delivery Data -->
            <div class="vx-col sm:w-1/2 w-full mb-base">
              <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>

              <!-- Allow Partial -->
              <div class="vx-row my-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Allow Partial</span>
                </div>
                <div class="vx-col vx-row sm:w-2/3 w-full">
                  <div class="vx-col sm:w-1/6">
                    <input type="radio" value="true" v-model="dt.allow_partial" :disabled="dt.allow_partial==true?!dt.allow_partial==true:true">
                    <label> Yes</label>
                  </div>
                  <div class="vx-col sm:w-1/6">
                    <input type="radio" value="false" v-model="dt.allow_partial" :disabled="dt.allow_partial==false?!dt.allow_partial==false:true">
                    <label> No</label>
                  </div>
                </div>
              </div>

              <!-- Delivery Type -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Delivery Type </span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.delivery_type" readonly/>
                </div>
              </div>

              <!-- Expense Type -->
              <div class="vx-row mb-6 mt-6" v-if="dt.coType.Code=='ZDSM'">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Expense Type </span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="dt.expense_type" readonly/>
                </div>
              </div>

              <!-- Req. Delivery Date -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Req. Delivery Date </span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-input class="w-full" :value="formatDate(dt.reques_delivery_date)" readonly/>
                </div>
              </div>

              <!-- Note -->
              <div class="vx-row mb-6 mt-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Note </span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-textarea class="w-full" :value="dt.note" readonly height="110px"/>
                </div>
              </div>

              <!-- Flush Out -->
              <div class="vx-row my-6">
                <div class="vx-col sm:w-1/3 w-full">
                  <span>Flush Out</span>
                </div>
                <div class="vx-col vx-row sm:w-2/3 w-full">
                  <div class="vx-col sm:w-1/6">
                    <input type="radio" value="true" v-model="dt.is_flush_out" :disabled="dt.is_flush_out==true?!dt.is_flush_out==true:true">
                    <label> Yes</label>
                  </div>
                  <div class="vx-col sm:w-1/6">
                    <input type="radio" value="false" v-model="dt.is_flush_out" :disabled="dt.is_flush_out==false?!dt.is_flush_out==false:true">
                    <label> No</label>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
          <!-- CO Line baru -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <table class="table table-bordered" style="width:100%">
                <thead>
                  <tr>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> SKU Code </th>
                    <th v-if="isHeadCombo" style="vertical-align: middle; text-align: center;" rowspan="2" colspan="2"> Item Combo </th>
                    <!-- <th v-if="isHeadCombo" style="vertical-align: middle; text-align: center;" rowspan="2">  -->
                      <!-- <div :style="'width:' + ( dt.customer_order_line&&dt.customer_order_line.length > 0 ? 50 : null ) + 'px'">HU Combo</div>  -->
                    <!-- </th> -->
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> HU Label</th>
                    <th style="vertical-align: middle; text-align: center;" colspan="2"> QTY </th>
                    <!-- Hide discount -->
                    <th style="vertical-align: middle; text-align: center;" colspan="4"> List Price </th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> Discount </th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> Rounding</th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> Tax </th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2"> Total </th>
                  </tr>
                  <tr>
                    <th style="vertical-align: middle; text-align: center;">HU</th>
                    <th style="vertical-align: middle; text-align: center;">UOM</th>
                    <th style="vertical-align: middle; text-align: center;"> Unit </th>
                    <th style="vertical-align: middle; text-align: center;"> Cogs </th>
                    <th style="vertical-align: middle; text-align: center;"> Price </th>
                    <th style="vertical-align: middle; text-align: center;"> Subtotal </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Struktur baru, array-of-object -->
                  <template v-for="(dtl, index) in dt.customer_order_line">
                    <!-- <vs-tr :key="index" v-for="(dt, index) in 3"> -->
                    <tr :key="index" v-if="!dtl.is_combo" :class="'table-expend'">
                      <td :class="[dtl.is_expand?'expand-color':'']" :rowspan="dtl.row_span" v-if="dtl.is_head&&(dtl.quantity > 0||dtl.is_combo) && parseFloat(dtl.price) > 0" style="vertical-align: middle;">
                        <strong>[{{dtl.sku_code}}] {{dtl.sku_name}} </strong><br>
                        <span class="badge badge-pill badge-success">{{ dtl.is_combo?"Combo Item":"Reguler" }}</span>
                        <span class="badge badge-pill badge-success ml-2" v-if="dtl.allow_partial==1">{{ "Allow_Partial" }}</span>
                      </td>
                      <!-- <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-if="isHeadCombo" v-on:click="expandNonCombo(i, index, dtl)" style="text-align: center; vertical-align: middle;" colspan="2" :rowspan="dtl.is_expand?2:null">-</td> -->
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" :colspan="isHeadCombo?2:null" v-on:click="expandNonCombo(i, index, dtl)" :rowspan="dtl.is_expand?2:null" v-if="isHeadCombo&&(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="text-align: center; vertical-align: middle;"> - </td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" :rowspan="dtl.is_expand?2:null" v-if="(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: center;">{{dtl.unit}}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" :rowspan="dtl.is_expand?2:null" v-if="(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: center;">{{dtl.quantity}}</td>
                      <td :class="[dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" :rowspan="dtl.row_span" v-if="dtl.is_head" style="vertical-align: middle;text-align: center;">{{ dtl.uom}} {{ dtl.uom_unit }}</td>
                      
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" :rowspan="dtl.is_expand?2:null" v-if="(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: center;">{{dtl.quantity}} {{dtl.unit}}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" style="vertical-align: middle;text-align: right;">{{ dtl.cogs?formatCurrency( parseFloat(dtl.cogs).toFixed(2) ):"" }}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" v-if="(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: right;">{{ !dtl.free_item?formatCurrency( parseFloat(dtl.price).toFixed(2) ):"" }}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" v-if="(dtl.quantity > 0 ||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: right;">{{ !dtl.free_item?formatCurrency( parseFloat(dtl.subtotal).toFixed(2) ):"" }}</td>
                      <!-- Hide discount -->
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" style="vertical-align: middle;text-align: right;" v-on:click="detailDiscShow(i,index)">
                          <a href="javascript:void(0);"><b>{{formatCurrency(parseFloat(dtl.discount).toFixed(3))}} </b><i class="fa fa-eye"></i></a>
                      </td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" v-if="(dtl.quantity > 0||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: right;">{{ !dtl.free_item?parseFloat(dtl.rounding).toFixed(2):"" }}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" v-if="(dtl.quantity > 0||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: right;">{{ !dtl.free_item?formatCurrency( parseFloat(dtl.tax).toFixed(2) ):"" }}</td>
                      <td :class="['expend-hover','pointer',dtl.is_expand?'expand-color':'']" v-on:click="expandNonCombo(i, index, dtl)" v-if="(dtl.quantity > 0||dtl.free_item) && parseFloat(dtl.price) > 0" style="vertical-align: middle;text-align: right;">{{ !dtl.free_item?formatCurrency( parseFloat( dtl.total).toFixed(2) ):"" }}</td>
                    </tr>
                    <tr :key="'expand-head-'+index+'-'+i" v-if="dtl.is_expand">
                        <td colspan="5" style="width:50%;">
                        <!-- expand-head -->
                          <tr>
                            <!-- <th>Width<br>
                              @{{ dtl.width }}
                            </th>
                            <th>
                              Height <br>
                              @{{ dtl.height }}
                            </th>
                            <th>Length<br>
                              @{{ dtl.length }}
                            </th> -->
                            <th>Weight<br>
                              @{{ dtl.weight }}
                            </th>
                            <th>Volume<br>
                              @{{ dtl.volume }}
                            </th>
                            <th>Net Weight<br>
                              @{{ dtl.net_weight }}
                            </th>
                            <th>Price Rule Code</th>
                            <th>Price Rule Valid From</th>
                            <th>Price Rule Valid To</th>
                            <th>Division Code</th>
                            <th>Division Description</th>
                          </tr>
                          <!-- expand-body -->
                          <tr>
                            <!-- <td>{{ dtl.total_width }}</td>
                            <td>{{ dtl.total_height }}</td>
                            <td>{{ dtl.total_length }}</td> -->
                            <td>{{ dtl.total_weight }}</td>
                            <td>{{ dtl.total_volume }}</td>
                            <td>{{ dtl.total_net_weight }}</td>
                            <td>{{ dtl.price_rule_code }}</td>
                            <td>{{ dtl.price_rule_valid_from }}</td>
                            <td>{{ dtl.price_rule_valid_to }}</td>
                            <td>{{ dtl.division_code }}</td>
                            <td>{{ dtl.division_description }}</td>
                          </tr>
                        </td>
                      </tr>

                    <tr :key="index+'combo'" v-if="dtl.is_combo">
                      <td style="vertical-align: middle;" :class="dtl.row_expend_head>0?'expand-color':''" :rowspan="dtl.detail_combo?getRowspanCombo(dtl.detail_combo):null"><b>[{{dtl.sku_code}}] {{ dtl.sku_name }}</b><br/>
                        <span class="badge badge-pill badge-success"
                        >Combo</span>
                      </td>
                    </tr>

                    <template v-for="(dtl_c, i_c) in dtl.detail_combo">
                      <vs-tr :key="index+'combo-child'+i+''+i_c" :class="'table-expend-combo'">
                        <vs-td :class="['combo-hover',dtl_c.is_expand?'expand-color':'']" :rowspan="dtl_c.is_expand?2:null" v-if="parseFloat(dtl_c.price) > 0" colspan="2">
                          <strong>[{{dtl_c.sku_code}}] {{dtl_c.item_name}}</strong><br>
                          <span class="badge badge-pill badge-success">{{ "Combo_Item" }}</span>
                          <span class="badge badge-pill badge-success" v-if="dtl_c.allow_partial==1">{{ "Allow_Partial" }}</span>
                        </vs-td>

                        <!-- <vs-td :class="dtl_c.row_expend_head>0?'expand-color':''" :rowspan="dtl.detail_combo?dtl.detail_combo.length+(dtl_c.row_expend_head?dtl_c.row_expend_head:0):null" v-if="i_c==0&&parseFloat(dtl_c.price) > 0" style="vertical-align: middle;text-align: center;">{{dtl.quantity}} {{ dtl.unit }}</vs-td> -->
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" style="vertical-align: middle;text-align: center;" :rowspan="dtl_c.is_expand?2:null" v-if="parseFloat(dtl_c.price) > 0" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{dtl_c.unit_name}}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" style="vertical-align: middle;text-align: center;" :rowspan="dtl_c.is_expand?2:null" v-if="parseFloat(dtl_c.price) > 0" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{dtl_c.quantity}} </vs-td>
                        <vs-td :class="dtl_c.row_expend_head>0?'expand-color':''" :rowspan="dtl.detail_combo?dtl.detail_combo.length+(dtl_c.row_expend_head?dtl_c.row_expend_head:0):null" v-if="i_c==0&&parseFloat(dtl_c.price) > 0" style="vertical-align: middle;text-align: center;">{{dtl.quantity}} {{ dtl.unit }}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" style="vertical-align: middle;text-align: center;" :rowspan="dtl_c.is_expand?2:null" v-if="parseFloat(dtl_c.price) > 0" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{dtl_c.quantity}} {{dtl_c.unit_name}}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{ dtl_c.cogs?dtl_c.cogs.toFixed(2):"" }}</vs-td>

                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{ !dtl.free_item?formatCurrency( parseFloat(dtl_c.price).toFixed(2) ):"" }}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{ !dtl.free_item?formatCurrency( parseFloat(dtl_c.subtotal).toFixed(2) ):"" }}</vs-td>
                        <!-- Hide discount -->
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="detailDiscShow(i, index, i_c)">
                          <a href="javascript:void(0);"><b>{{ !dtl_c.free_item?formatCurrency( parseFloat(dtl_c.discount).toFixed(3) ):"" }} </b><i class="fa fa-eye"></i></a>
                        </vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{formatCurrency( parseFloat(dtl_c.rounding).toFixed(2) )}}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{formatCurrency( parseFloat(dtl_c.tax).toFixed(2) )}}</vs-td>
                        <vs-td :class="['combo-hover','pointer',dtl_c.is_expand?'expand-color':'']" v-if="parseFloat(dtl_c.price) > 0" style="text-align: right;" v-on:click.native="expandCombo(i, index, i_c, dtl_c)">{{ formatCurrency( parseFloat(dtl_c.total).toFixed(2) )}}</vs-td>
                      </vs-tr>

                      <tr :key="'expand-head-'+index+'-'+i+''+i_c" v-if="dtl_c.is_expand && dtl.detail_combo.length!=0">
                        <td colspan="5" style="width:50%;">
                        <!-- expand-head -->
                          <tr>
                            <!-- <th>Width<br>
                              @{{ dtl_c.width }}
                            </th>
                            <th>
                              Height <br>
                              @{{ dtl_c.height }}
                            </th>
                            <th>Length<br>
                              @{{ dtl_c.length }}
                            </th> -->
                            <th>Weight<br>
                              @{{ dtl_c.weight }}
                            </th>
                            <th>Volume<br>
                              @{{ dtl_c.volume }}
                            </th>
                            <th>Net Weight<br>
                              @{{ dtl_c.net_weight }}
                            </th>
                            <th>Price Rule Code</th>
                            <th>Price Rule Valid From</th>
                            <th>Price Rule Valid To</th>
                            <th>Division Code</th>
                            <th>Division Description</th>
                          </tr>
                          <!-- expand-body -->
                          <tr>
                            <!-- <td>{{ dtl_c.total_width }}</td>
                            <td>{{ dtl_c.total_height }}</td>
                            <td>{{ dtl_c.total_length }}</td> -->
                            <td>{{ dtl_c.total_weight }}</td>
                            <td>{{ dtl_c.total_volume }}</td>
                            <td>{{ dtl_c.total_net_weight }}</td>
                            <td>{{ dtl_c.price_rule_code }}</td>
                            <td>{{ dtl_c.price_rule_valid_from }}</td>
                            <td>{{ dtl_c.price_rule_valid_to }}</td>
                            <td>{{ dtl_c.division_code }}</td>
                            <td>{{ dtl_c.division_description }}</td>
                          </tr>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <!-- free good / free item -->
                  <template v-if="dataFgAll.length>0">
                      <vs-tr>
                        <vs-td colspan="100%" class="p-0"> <vs-divider><b>Free Good</b></vs-divider></vs-td>
                      </vs-tr>
                      <tr>
                        <th style="vertical-align: middle; text-align: center;">SKU Code</th>
                        <th style="vertical-align: middle; text-align: center;">Promotion Type</th>
                        <th style="vertical-align: middle; text-align: center;" :colspan="!isHeadCombo?2:null">Promotion Code</th>
                        <th v-if="isHeadCombo"></th>
                        <th v-if="isHeadCombo" colspan="2"></th>
                        <th style="vertical-align: middle; text-align:center;">Unit</th>
                        <!-- <th style="vertical-align: middle; text-align:center;">QTY</th> -->
                        <th style="vertical-align: middle; text-align: center;">Cogs</th>
                        <th style="vertical-align: middle; text-align: center;">Price</th>
                        <th style="vertical-align: middle; text-align:center;">Subtotal</th>
                        <th style="vertical-align: middle; text-align:center;">Discount</th>
                        <th style="vertical-align: middle; text-align:center;"></th>
                        <th style="vertical-align: middle; text-align:center;"></th>
                        
                        <!-- <th style="vertical-align: middle; text-align:center;"></th>
                        <th style="vertical-align: middle; text-align:center;"></th>
                        <th style="vertical-align: middle; text-align:center;"></th>
                        <th v-if="isExistSkuCombo"></th> -->
                      </tr>
                      <template v-for="(dtl_fg, i_fg) in dataFgAll">
                      <vs-tr :key="index+'fg'+i_fg" :class="'table-expend'">
                        <vs-td :class="['expend-hover',dtl_fg.is_expand?'expand-color':'']" style="vertical-align: middle;" :rowspan="dtl_fg.is_expand?2:null" v-if="parseFloat(dtl_fg.price) > 0">
                          <strong>[{{dtl_fg.sku_code}}] {{dtl_fg.sku_name}}</strong><br>
                          <span class="badge badge-pill badge-success" v-if="dtl_fg.allow_partial==1">{{ "Allow_Partial" }}</span>
                        </vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" :rowspan="dtl_fg.is_expand?2:null" v-if="parseFloat(dtl_fg.price) > 0" style="vertical-align: middle; text-align: center;"> {{dtl_fg.fg_component.promotion_type}} </vs-td>
                        <td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" style="text-align: center; vertical-align: middle;" :rowspan="dtl_fg.is_expand?2:null" :colspan="!isHeadCombo?2:null" >{{dtl_fg.fg_component.promotion_code}}</td>
                        <td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="isHeadCombo" style="text-align: center; vertical-align: middle;" :rowspan="dtl_fg.is_expand?2:null"> - </td>
                        <td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="isHeadCombo" style="text-align: center; vertical-align: middle;" :rowspan="dtl_fg.is_expand?2:null" colspan="2" > - </td>
                        <!-- <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" :rowspan="dtl_fg.is_expand?2:null" v-if="parseFloat(dtl_fg.price) > 0">{{dtl_fg.unit}}</vs-td> -->
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" :rowspan="dtl_fg.is_expand?2:null" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: center;">{{dtl_fg.quantity}} {{dtl_fg.unit}}</vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;">{{ dtl_fg.cogs?formatCurrency( parseFloat(dtl_fg.cogs).toFixed(2) ):''}}</vs-td>

                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;">{{ formatCurrency( (parseFloat(dtl_fg.price)/parseFloat(dtl_fg.quantity)).toFixed(2) )}}</vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;">{{ formatCurrency( parseFloat(dtl_fg.price).toFixed(2) )}}</vs-td>
                        <!-- Hide discount -->
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-on:click.native="expandFg(i_fg)" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;">{{ formatCurrency( parseFloat(dtl_fg.discount).toFixed(3) )}} </vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;"></vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;"></vs-td>
                        <vs-td :class="['expend-hover','pointer',dtl_fg.is_expand?'expand-color':'']" v-if="parseFloat(dtl_fg.price) > 0" style="text-align: right;"></vs-td>
                      </vs-tr>

                      <tr :key="'expand-head-fg'+index+'-'+i_fg" v-if="dtl_fg.is_expand">
                        <td colspan="5" style="width:50%;">
                        <!-- expand-head -->
                          <tr>
                            <!-- <th>Width<br>
                              @{{ dtl_fg.width }}
                            </th>
                            <th>
                              Height <br>
                              @{{ dtl_fg.height }}
                            </th>
                            <th>Length<br>
                              @{{ dtl_fg.length }}
                            </th> -->
                            <th>Weight<br>
                              @{{ dtl_fg.weight }}
                            </th>
                            <th>Volume<br>
                              @{{ dtl_fg.volume }}
                            </th>
                            <th>Net Weight<br>
                              @{{ dtl_fg.net_weight }}
                            </th>
                            <th>Price Rule Code</th>
                            <th>Price Rule Valid From</th>
                            <th>Price Rule Valid To</th>
                            <th>Division Code</th>
                            <th>Division Description</th>
                          </tr>
                          <!-- expand-body -->
                          <tr>
                            <!-- <td>{{ dtl_fg.total_width }}</td>
                            <td>{{ dtl_fg.total_height }}</td>
                            <td>{{ dtl_fg.total_length }}</td> -->
                            <td>{{ dtl_fg.total_weight }}</td>
                            <td>{{ dtl_fg.total_volume }}</td>
                            <td>{{ dtl_fg.total_net_weight }}</td>
                            <td>{{ dtl_fg.price_rule_code }}</td>
                            <td>{{ dtl_fg.price_rule_valid_from }}</td>
                            <td>{{ dtl_fg.price_rule_valid_to }}</td>
                            <td>{{ dtl_fg.division_code }}</td>
                            <td>{{ dtl_fg.division_description }}</td>
                          </tr>
                        </td>
                      </tr>
                    </template>
                    </template>
                  <tr v-if="dt.all_total != null">
                    <td :colspan="isHeadCombo?9:7" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
                    <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(dt.all_total.total_netto.toFixed(2)) }}</b> </td>
                    <!-- Hide discount -->
                    <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(dt.all_total.total_discount.toFixed(3)) }}</b> </td>
                    <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(dt.all_total.total_rounding.toFixed(2)) }}</b> </td>
                    <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(dt.all_total.total_tax.toFixed(2)) }}</b> </td>
                    <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(dt.all_total.total_billing.toFixed(2)) }}</b> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- End -->
          <!-- Additional layout -->
          <div class="vx-row">
            <div class="vx-col w-1/2">
            </div>
            <div class="vx-col w-1/2">
              <table class="table" style="width:100%">
                <thead>
                  <tr>
                    <td style="text-align: right;"><b>Total Net Weight</b></td>
                    <td style="text-align: right;">{{ formatCurrency(dt.all_total.total_net_value.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: right;"><b>Total Weight</b></td>
                    <td style="text-align: right;">{{ formatCurrency(dt.all_total.total_weight.toFixed(2)) }}</td>

                  </tr>
                  <tr>
                    <td style="text-align: right;"><b>Total Volume</b></td>
                    <!-- <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_volume) }}</td> -->
                    <!-- <td style="text-align: right;">{{ "this.CalcRespData.total_volume" }}</td> -->
                    <td style="text-align: right;">{{ formatCurrency(dt.all_total.total_volume.toFixed(2)) }}</td>

                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <vs-popup fullscreen title="Discount Details" :active.sync="isDetailDisc">
      <div class="vx-row m-2 mt-6">
        <div class="vx-col w-full" v-if="isDetailDisc">
          <DetailDiscount :DetailDataDiscount="detailDataDiscount" :DetailPricing="detailPricing" ></DetailDiscount>
        </div>
      </div>
    </vs-popup>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
import DetailDiscount from "./detail_discount.vue"
import AddressComponent from "@/components/master/Address";
export default {
  components :{
    Datepicker,
    FormInput,
    AddressComponent,
    DetailDiscount,
  },
  props: ["coID"],

  data() {
    let id = parseInt(this.$route.params.id);
    // const edit = true;
    if (this.coID) {
      id = this.coID
    }
    // 1=quantity, 2=value, 3=weight, 4=volume
    // 1=percentage, 2=amount, 5=lowest, 6=sku, 9=choice
    return {
      baseString:["", "quantity", "value", "weight", "volume"],
      discountString:["", "percentage", "amount", "lowest", "sku", "choice"],
      showOTCForm: false,
      rendered: true,
      tabIndex: 0,
      coId: id,
      isHeadCombo:false,
      detailDataDiscount:[],
      detailPricing:{
        pricing_code:"",
        price:"",
        sub_total:"",
        tax:"",
        rounding:"",
        nett:"",
        gross_up:"",
        dpp:"",
        grand_total:"",
        sku:"",
      },
      pricingCode:"",
      pricing:0,
      isDetailDisc:false,
      // baseUrl: "/api/sfa/v1/customer-order/edit/"+id,
      baseUrl: "/api/sfa/v1/customer-order/find-by-co-id/",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      dataFG:[],
      dataFgAll:[],
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",
      dataCustomerOrder:[],
      selectedCustDeposit:[],
      optionCustDeposit:[],
      title:"View Sales Order",
      id: id,

      isOneTime: false,

      oneTimeCust: {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      },
      optionSoType: [],
    };
  },
  methods: {
    expandNonCombo(iCO, iLine){
      let pass = false
      if (pass) {
        return
      }
      console.log("this.dataCustomerOrder[iCO].customer_order_line=>",this.dataCustomerOrder[iCO].customer_order_line)
      let is_expand = this.dataCustomerOrder[iCO].customer_order_line[iLine].is_expand;
      let iline2 = 0
      this.dataCustomerOrder[iCO].customer_order_line.every((el,i)=>{
        if (el.sku_code==this.dataCustomerOrder[iCO].customer_order_line[iLine].sku_code&&el.is_head==true) {
          iline2 = i
          return false
        }
        return true
      })
      let row_expand = this.dataCustomerOrder[iCO].customer_order_line[iline2].row_span;
      console.log(iLine,"row_expand before=>",row_expand)
      if (row_expand==undefined) {
        row_expand=0
      }
      console.log("!is_expand==false=>",!is_expand==false)
      console.log("row_expand>0=>",row_expand>0)
      if (!is_expand==false&&row_expand>0) {
        row_expand--
      }else if (!is_expand==true) {
        row_expand++
      }else{
        row_expand = 0
      }
      console.log("row_expand line after=>",row_expand)
      this.dataCustomerOrder[iCO].customer_order_line[iline2].row_span = row_expand;
      this.dataCustomerOrder[iCO].customer_order_line[iLine].is_expand = !is_expand;
    },
    expandCombo(iCO, iLine, iCombo, data){
      let is_expand = this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[iCombo].is_expand;
      let row_expend_head = this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[0].row_expend_head;
      if (row_expend_head==undefined) {
        row_expend_head = 0
      }
      if (!is_expand==false&&row_expend_head>0) {
        row_expend_head--
      }else if (!is_expand==true) {
        row_expend_head++
      }else{
        row_expend_head = 0
      }
      this.dataCustomerOrder[iCO].customer_order_line[iLine].row_expend_head = row_expend_head
      this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[0].row_expend_head = row_expend_head
      this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[iCombo].is_expand = !is_expand;
    },
    expandFg(ifg){
      let is_expand = this.dataFgAll[ifg].is_expand;
      this.dataFgAll[ifg].is_expand = !is_expand;
    },
    getRowspanCombo(data_combo){
      console.log("data_combo",data_combo)
      let rowspan = data_combo.length + 1
      data_combo.forEach(el => {
        console.log("item_combo",el)
        if(el.is_expand) {
          rowspan++;
        }
      });
      return rowspan;
    },
    showOTForm(){
      this.showOTCForm = true;
      // console.log("tampil", this.showOTCForm);
    },
    handleCloseOt() {
      this.showOTCForm = false;
    },
    handleClose() {
      // back to list
      if (this.coID) {
        this.$emit("handleClose");
      }else{
        this.$router.push({ name: "sales-order" });
      }
    },
    formatCurrency(value) {
      if (value<=0) {
          return "0"
      }
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    getData_() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.baseUrl + this.coId).then( async(resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.dataCustomerOrder = resp.data.records[0];
            // console.log("resolve 10 lho - 2611")
            resolve("resolve lho");
          } else {
            // console.log(resp);
            reject("reject lho");
          }
        });
      });
    },
    getDataInit_() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.baseUrl + this.coId).then( async(resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.dataCustomerOrder = resp.data;

            // tata ulang data line
            // console.log("dataCustomerOrder.length")
            for (let i = 0; i < this.dataCustomerOrder.length; i++) {
              let all_total = {
                total_netto: 0,
                total_rounding: 0,
                total_tax: 0,
                total_billing: 0,
                total_discount: 0,
                total_net_value: 0,
                total_weight: 0,
                total_volume: 0,
              }
              this.dataCustomerOrder[i].coType = this.getSoType(this.dataCustomerOrder[i].category);
              console.log("this.dataCustomerOrder[i].coType",this.dataCustomerOrder[i].coType)
              this.dataCustomerOrder[i].all_total = {};
              this.dataCustomerOrder[i].is_combo = false
              let coLineSplit = this.dataCustomerOrder[i].customer_order_line;
              let coLineOnly = this.dataCustomerOrder[i].customer_order_line;
              all_total.total_net_value = 0;
              all_total.total_weight    = 0;
              all_total.total_volume    = 0;
              // console.log("coLineSplit.length")
              this.dataCustomerOrder[i].customer_order_line = [];
              for (let j = 0; j < coLineSplit.length; j++) {
                let line_data = {};
                let detailHu = coLineSplit[j].customer_order_line_qty_splitter;
                let itemUnit = coLineSplit[j].item_unit;
                // if (!coLineSplit[j].free_item) {
                if (!coLineSplit[j].free_item) {
                  let detail_combo = [{
                        item_name : "",
                        sku_code : "",
                        quantity : 0,
                        division_code : "",
                        unit_name : "",
                        price : 0,
                        discount : 0,
                        rounding : 0,
                      }],is_combo = false;
                  detail_combo = []
                  for (let k = 0; k < detailHu.length; k++) {
                    let itemUnitHu = detailHu[k].item_unit;
                    // console.log("detailHu.length finish")
                    // coLineSplit[j].combo_code = ""
                    if (coLineSplit[j].combo_code!=undefined&&coLineSplit[j].combo_code!="") {
                      // detail_combo = []
                      console.log("detailHu[k]",detailHu[k]);
                      // if (detailHu[k].detail_combo) {
                      //   detailHu[k].detail_combo.forEach((el_combo)=>{
                      let item_name = "",item_unit = "",item_code = ""
                        if (itemUnitHu.item!=undefined) {
                          item_name = itemUnitHu.item.name
                          item_code = itemUnitHu.item.sku_code
                        }
                        if (itemUnitHu.unit!=undefined) {
                          item_unit = itemUnitHu.unit.Name
                        }
                          is_combo = true
                          this.dataCustomerOrder[i].is_combo = true
                          this.isHeadCombo = true
                          detail_combo.push({
                            item_name : item_name,
                            sku_code : item_code,
                            quantity : detailHu[k].quantity,
                            line:coLineSplit[j].line,
                            unit_name : item_unit,
                            price : detailHu[k].quantity != 0 ? (detailHu[k].price / detailHu[k].quantity) : 0,
                            discount : detailHu[k].total_discount,
                            // total_discount : el_combo.TotalDiscount,
                            rounding : detailHu[k].rounding,
                            tax :detailHu[k].tax,
                            subtotal :detailHu[k].price,
                            total :detailHu[k].total,
                            item_type:itemUnitHu.item.item_type.type,
                            item_type_name:itemUnitHu.item.item_type.name,
                            is_expand: false,
                            width:detailHu[k].width,
                            total_width:detailHu[k].total_width,
                            weight:detailHu[k].weight,
                            total_weight:detailHu[k].total_weight,
                            height:detailHu[k].height,
                            total_height:detailHu[k].total_height,
                            length:detailHu[k].length,
                            total_length:detailHu[k].total_length,
                            volume:detailHu[k].volume,
                            total_volume:detailHu[k].total_volume,
                            net_weight:detailHu[k].net_weight,
                            total_net_weight:detailHu[k].total_net_weight,
                            price_rule_code:detailHu[k].price_rule_code,
                            price_rule_valid_from:moment(detailHu[k].price_rule_valid_from).format("YYYY-MM-DD"),
                            price_rule_valid_to:moment(detailHu[k].price_rule_valid_to).format("YYYY-MM-DD"),
                            division_code : detailHu[k].division_code,
                            division_description:detailHu[k].division_description,
                            promotion:detailHu[k].promotion?detailHu[k].promotion:[],
                            cogs:detailHu[k].cogs,
                            gross_up:detailHu[k].gross_up,
                            allow_partial:coLineSplit[j].allow_partial
                            
                          })
                        // })
                      // }
                    }else{
                      let uom_unit =""
                    if (coLineSplit[j].item_unit!=undefined) {
                      if (coLineSplit[j].item_unit.unit!=undefined) {
                        uom_unit = coLineSplit[j].item_unit.unit.Name
                      }
                    }
                      line_data = {
                        sku_code : itemUnitHu.item.sku_code,
                        sku_name : itemUnitHu.item.name,
                        uom : coLineSplit[j].quantity,
                        uom_unit : uom_unit,
                        is_head :k==0,
                        row_span:detailHu.length,
                        quantity : detailHu[k].quantity,
                        unit : detailHu[k].unit_name,
                        price : detailHu[k].quantity != 0 ? (detailHu[k].price / detailHu[k].quantity) : 0,
                        subtotal : detailHu[k].price,
                        discount : detailHu[k].total_discount,
                        tax : detailHu[k].tax,
                        total : detailHu[k].total,
                        rounding : detailHu[k].rounding,
                        line :coLineSplit[j].line,
                        is_combo : is_combo,
                        free_item:false,
                        item_type: itemUnitHu.item.item_type.type,
                        item_type_name: itemUnitHu.item.item_type.name,
                        detail_combo:detail_combo,
                        is_expand: false,
                        width:detailHu[k].width,
                        total_width:detailHu[k].total_width,
                        weight:detailHu[k].weight,
                        total_weight:detailHu[k].total_weight,
                        height:detailHu[k].height,
                        total_height:detailHu[k].total_height,
                        length:detailHu[k].length,
                        total_length:detailHu[k].total_length,
                        volume:detailHu[k].volume,
                        total_volume:detailHu[k].total_volume,
                        net_weight:detailHu[k].net_weight,
                        total_net_weight:detailHu[k].total_net_weight,
                        price_rule_code:detailHu[k].price_rule_code,
                        price_rule_valid_from:moment(detailHu[k].price_rule_valid_from).format("YYYY-MM-DD"),
                        price_rule_valid_to:moment(detailHu[k].price_rule_valid_to).format("YYYY-MM-DD"),
                        division_description:detailHu[k].division_description,
                        division_code:detailHu[k].division_code,
                        promotion:detailHu[k].promotion?detailHu[k].promotion:[],
                        cogs:detailHu[k].cogs,
                        gross_up:detailHu[k].gross_up,
                        allow_partial:coLineSplit[j].allow_partial
                      }
                      this.dataCustomerOrder[i].customer_order_line.push(line_data);
                    }
                    all_total.total_netto += detailHu[k].price;
                    all_total.total_rounding += detailHu[k].rounding;
                    all_total.total_tax += detailHu[k].tax;
                    all_total.total_billing += detailHu[k].total;
                    all_total.total_discount += detailHu[k].total_discount;

                    // additional info, net val, weight, and volume
                    all_total.total_net_value += detailHu[k].total_net_weight;
                    all_total.total_weight    += detailHu[k].total_weight;
                    all_total.total_volume    += detailHu[k].total_volume;
                    console.log("all_total=>",all_total)
                    this.dataCustomerOrder[i].all_total = all_total;
                  }
                  // coLineSplit[j].combo_code = ""
                  if (coLineSplit[j].combo_code!=undefined&&coLineSplit[j].combo_code!="") {
                    let combo_unit ="", item_type ="", item_name =""
                    if (coLineSplit[j].item_unit!=undefined) {
                      if (coLineSplit[j].item_unit.unit!=undefined) {
                        combo_unit = coLineSplit[j].item_unit.unit.Name
                      }
                      if (coLineSplit[j].item_unit.item_type!=undefined) {
                        item_type = coLineSplit[j].item_unit.item_type.type
                        item_name = coLineSplit[j].item_unit.item_type.name
                      }
                    }
                    this.dataCustomerOrder[i].customer_order_line.push({
                          sku_code : coLineSplit[j].combo_code,
                          sku_name : coLineSplit[j].combo_name,
                          quantity : coLineSplit[j].quantity_combo,
                          uom : coLineSplit[j].quantity_combo,
                          allow_partial:coLineSplit[j].allow_partial,
                          unit : combo_unit,
                          price : 0,
                          subtotal : 0,
                          discount : 0,
                          tax : 0,
                          total : 0,
                          rounding : 0,
                          line :coLineSplit[j].line,
                          is_combo : true,
                          free_item:false,
                          item_type: item_type,
                          item_type_name: item_name,
                          detail_combo:detail_combo,
                          is_expand: false,
                          width:0,
                          total_width:0,
                          weight:0,
                          total_weight:0,
                          height:0,
                          total_height:0,
                          length:0,
                          total_length:0,
                          volume:0,
                          total_volume:0,
                          net_weight:0,
                          total_net_weight:0,
                          price_rule_code:"",
                          price_rule_valid_from:"",
                          price_rule_valid_to:"",
                          division_description:"",
                          division_code:"",
                          promotion:[],
                          cogs:0,
                          gross_up:0
                    })
                  }
                }else{
                  let promotion =[]
                  if (coLineOnly[j].promotion) {
                    promotion = coLineOnly[j].promotion
                  }else{
                    promotion = []
                  }
                  line_data = {
                    allow_partial : coLineOnly[j].allow_partial,
                    sku_code : itemUnit.item.sku_code,
                    sku_name : itemUnit.item.name,
                    quantity : coLineOnly[j].quantity,
                    line : coLineOnly[j].line,
                    unit : itemUnit.unit.Name,
                    price : coLineOnly[j].price,
                    subtotal : 0,
                    discount : coLineOnly[j].discount,
                    tax : 0,
                    total : 0,
                    rounding : 0,
                    free_item:true,
                    item_type:"",
                    item_type_name:"",
                    is_expand: false,
                    width:0,
                    total_width:0,
                    weight:0,
                    total_weight:0,
                    height:0,
                    total_height:0,
                    length:0,
                    total_length:0,
                    volume:0,
                    total_volume:0,
                    net_weight:0,
                    total_net_weight:0,
                    price_rule_code:0,
                    price_rule_valid_from:"-",
                    price_rule_valid_to:"-",
                    division_description:"-",
                    division_code:"-",
                    cogs:coLineOnly[j].cogs,
                    fg_component:{
                      disabled: true,

                      validate: "required",
                      name: "fg_sku",
                      placeholder: "Sku Code",
                      head:"Sku Code",
                      type: "multiselect",
                      track_by:"sku_code",
                      isLoading:false,
                      multiple: false,
                      allowempty: false,
                      promotion_code:promotion.length?promotion[0].promotion_code:"",
                      promotion_type:promotion.length?promotion[0].promotion_type:"",
                      promotion:promotion,
                      value: {
                        sku_code:itemUnit.item.sku_code,
                        item_id:itemUnit.item_id,
                        item_name:itemUnit.item.name,
                        item_unit:itemUnit.unit.Name,
                        item_unit_id:itemUnit.id,
                        text:"["+itemUnit.item.sku_code+"] "+itemUnit.item.name,
                        qty:coLineOnly[j].quantity,
                        price:coLineOnly[j].price?coLineOnly[j].price/(coLineOnly[j].quantity>0?coLineOnly[j].quantity:0):0,
                        subtotal:coLineOnly[j].price?coLineOnly[j].price:0,
                        discount:coLineOnly[j].discount,
                        cap:promotion.length?promotion[0].cap:0,
                        capped:promotion.length?promotion[0].capped:false,
                        cogs:promotion.length?promotion[0].cogs:false,
                      },
                      optionValue:[],
                    }
                  }
                  console.log("free_item",line_data)
                  this.dataFgAll.push(line_data);
                }
              }
            }
            console.log("this.dataCustomerOrder",this.dataCustomerOrder)
            resolve(resp);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error Get Data",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            reject(resp);
          }
          this.filterDataFg(this.dataCustomerOrder[0].customer_order_line[0].line)
        });
      });
    },
    getSOTypes(){
      // this.$vs.loading();
      return new Promise((resolve, reject) => {
      // this.$http.get("api/v1/master/sales-order-type", {
      this.$http.get("api/oms/v1/sfa-sales-order/sales-order-type", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
            // console.log(resp)
            if (resp.code == 200) {
              this.optionSoType = resp.data.records;
              resolve(true);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Sales Order Type option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                reject(false);
            }
          // this.$vs.loading.close();
        });
      });
    },
    formatDate(value) {
      // console.log(value)
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    getSoType(code){
      let soType = this.optionSoType.filter((v) => {
        console.log(v.Code,"==",code,v.Code == code)
        if(v.Code == code){
          return v
        }
      });
      soType = soType[0];
      return soType;
    },
    async getCustDeposit_(cust_id) {
      let resp = await this.$http.get(
        `api/oms/v1/sfa-sales-order/cash-bank/customer-deposit/${cust_id}`
      );
      console.log("resp", resp);
      console.log(resp);
      if (resp.status == "success") {
        console.log("data cust order inside getCustDeposit_", this.dataCustomerOrder)
        if(resp.data.cashBank.length > 0){
          this.optionCustDeposit = resp.data.cashBank;
          console.log("677 this.optionCustDeposit", this.optionCustDeposit)
          
          if (this.dataCustomerOrder[0].co_deposits.length > 0) {
            this.dataCustomerOrder[0].co_deposits.forEach(cod => {
              let cd = {
                Code: cod.DepositCode,
                CustomerID: this.dataCustomerOrder[0].customer_id,
                DepositValue: cod.Value,
                ID: cod.DepositID,
              }
              this.selectedCustDeposit.push(cd);
              this.optionCustDeposit.push(cd);
            });
            console.log("688 this.selectedCustDeposit", this.selectedCustDeposit)
          }
        } else {
          if (this.dataCustomerOrder[0].co_deposits.length > 0) {
            this.dataCustomerOrder[0].co_deposits.forEach(cod => {
              let cd = {
                Code: cod.DepositCode,
                CustomerID: this.dataCustomerOrder[0].customer_id,
                DepositValue: cod.Value,
                ID: cod.DepositID,
              }
              this.selectedCustDeposit.push(cd);
              this.optionCustDeposit.push(cd);
            });
            console.log("688 this.selectedCustDeposit", this.selectedCustDeposit)
          } else if (this.dataCustomerOrder[0].co_deposits.length == 0 && resp.data.cashBank.length == 0) {
            this.$vs.notify({
              title: "Warning",
              text: "No deposit found ! Please add or create one first !",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
        }
      }
    },
    redirectTab(i){
      this.filterDataFg(this.dataCustomerOrder[i].customer_order_line[0].line)
    },
    filterDataFg(line){
      this.dataFG = this.dataFgAll.filter(el=>
        el.line == line
      )
    },
    detailDiscShow(i,i2,i_c){
      this.isDetailDisc = true
      let promotion = [],subtotal = 0, tax = 0, rounding = 0, gross_up = 0,total = 0, sku_name ="", unit = "", discount = 0
      if (this.dataCustomerOrder[i].customer_order_line&&this.dataCustomerOrder[i].customer_order_line.length>0) {
        if (i_c>=0) {
          this.pricingCode = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].price_rule_code
          this.pricing = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].price.toFixed(2)
          promotion = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].promotion

          subtotal = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].subtotal
          tax = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].tax
          rounding = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].rounding
          gross_up = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].gross_up
          total = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].total
          sku_name = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].item_name
          unit = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].unit_name
          discount = this.dataCustomerOrder[i].customer_order_line[i2].detail_combo[i_c].discount
        }else{
          this.pricingCode = this.dataCustomerOrder[i].customer_order_line[i2].price_rule_code
          this.pricing = this.dataCustomerOrder[i].customer_order_line[i2].price.toFixed(2)
          promotion = this.dataCustomerOrder[i].customer_order_line[i2].promotion
          
          subtotal = this.dataCustomerOrder[i].customer_order_line[i2].subtotal
          tax = this.dataCustomerOrder[i].customer_order_line[i2].tax
          rounding = this.dataCustomerOrder[i].customer_order_line[i2].rounding
          gross_up = this.dataCustomerOrder[i].customer_order_line[i2].gross_up
          total = this.dataCustomerOrder[i].customer_order_line[i2].total
          sku_name = this.dataCustomerOrder[i].customer_order_line[i2].sku_name
          unit = this.dataCustomerOrder[i].customer_order_line[i2].unit
          discount = this.dataCustomerOrder[i].customer_order_line[i2].discount
        }
        this.detailDataDiscount = [];
        this.detailPricing= {
          "pricing_code": this.pricingCode,
          "price": this.pricing,
          "sub_total": subtotal,
          "tax": tax,
          "rounding": rounding,
          "nett": subtotal - discount + rounding,
          "gross_up": gross_up,
          "dpp":  parseFloat(subtotal)-
            parseFloat(discount)+
            parseFloat(rounding),
          "grand_total": total,
          "sku": sku_name+" "+unit
        }
        promotion.forEach((el)=>{
          this.detailDataDiscount.push({
            "add":el.add,
            "add_rate":el.add_rate,
            "amount":el.amount,
            "base":el.base,
            "base_amount":el.base_amount,
            "base_string":this.baseString[el.base?el.base:0],
            "cap":el.cap,
            "capped":el.capped,
            "discount_string":this.discountString[el.discount_type?el.discount_type:0],
            "discount_type":el.discount_type,
            "from":el.from,
            "main":el.main,
            "main_rate":el.main_rate,
            "order_line":el.order_line,
            "order_line_after_discount":el.order_line_after_discount,
            "promotion_code":el.promotion_code,
            "promotion_id":el.promotion_id,
            "promotion_item_req_id":el.promotion_item_req_id,
            "promotion_sequence":el.promotion_sequence,
            "promotion_step_id":el.promotion_step_id,
            "promotion_type":el.promotion_type,
            "qty":el.qty,
            "to":el.to,
            // "valid":promotion.valid
          })
        })
      }else{
        this.detailDataDiscount = []
      }
    },
    getCustDeposit(cust_id) {
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/cash-bank/customer-deposit/" + cust_id).then((resp) => {
        console.log(resp);
        if (resp.status == "success") {
          if(resp.data.cashBank.length > 0){
            this.optionCustDeposit = resp.data.cashBank;
            console.log("657 this.optionCustDeposit", this.optionCustDeposit)
          } else {
            this.$vs.notify({
              title: "Warning",
              text: "No deposit found ! Please add or create one first !",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    customLableCustDeposit({ Code, DepositValue }){
      return `${Code} - Value : ${this.formatCurrency(DepositValue)}`;
    },
  },
  filters: {
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  mounted() {
    this.$vs.loading();
    Promise.all([
      this.getSOTypes(),
      this.getDataInit_(),
    ]).then((r) => {

      if(this.dataCustomerOrder[0].category == "ZDCBD"){
        this.getCustDeposit_( parseInt(this.dataCustomerOrder[0].customer_id));
      }

      this.rendered = false;
      this.$nextTick(()=>{
        this.rendered = true;
      });
      this.$vs.loading.close();
      this.$vs.notify({
          color: "success",
          title: "Get Data",
          text: r[0].message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
    }).catch(function (error) {
      // console.log(error);
    });
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.badge.badge-success {
  background-color: #28c76f;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-pill {
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 10rem;
  margin: 1px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
.pointer{
  cursor: pointer;
}
.table-expend:hover .expend-hover,.table-expend-combo:hover td.combo-hover{
    background: #e9e9e9;
    /* cursor: pointer; */
}
.table-expend:active .expend-hover,.table-expend-combo:active td.combo-hover{
    background: #c7c7c7;
}
.expand-color{
  background: #e9e9e9a1;
}
</style>
